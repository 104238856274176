.css-form input[type=text], select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
  }

  textarea{
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
  }
  
  .css-form input[type=submit] {
    width: 100%;
    background-color: #29ABE2;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .css-form input[type=submit]:hover {
    background-color: #45a049;
  }
  
  .css-form {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
    margin-top: 100px;
  }